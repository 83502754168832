* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  @media (min-width: 38em) {
    font-size: 20px;
}
}

body {
  color: #515151;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  //color: #1a191b;
  color: #0081ff;
  text-decoration: none;
  transition: .3s;
}

strong, b {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.1;
  color: #1a191b;
  letter-spacing: -.025rem;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.3rem;
}

h4,
h5,
h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}

img {
  max-width: 100%;
}

.size {
  width: 900px;
  max-width: 90%;
}

.margin {
  margin: 0 auto;
}

.min-height {
  min-height: calc(100vh - 80px - 200px);
  padding-top: 20px;
}
