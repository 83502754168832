$colortheme: #0081ff;
$colortext: #fff;
$baseurl: '';

@import "base";
@import "menu";
@import "header";
@import "footer";
@import "layout";
@import "icons";
@import "post";
@import "breadcrumbs";

.margin-ul ul {
    margin: 0 0 30px 40px;
}


table{
    border-collapse: collapse;
    border-spacing: 0;
    border:2px solid #000000;
}

th{
    border:2px solid #000000;
    padding: 10px;
}

td{
    border:2px solid #000000;
    padding: 10px;
}

.list-decimal{
    list-style-type: decimal;
}