.content-post {
  min-height: calc(100vh - 480px);
  background: #f3f5f7;
  padding-top: 30px;
  padding-bottom: 30px;
}

.list-item {
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  border: 1px solid #C1C1C1;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  transition: .3s;
  overflow: hidden;
  cursor: pointer;
    &:last-child {
      margin-bottom: 0px;
    }

    &:hover {
      border-color: $colortheme;
    }

    .list-item-icon {
      flex-basis: 100px;
      padding-right: 20px;
      font-size: 2rem;
      color: $colortheme;
    }

    .list-post-title {
      font-size: 22px;
      transition: .3s;
      color: $colortheme;
    }
    .list-post-description {
      font-size: 16px
    }

    .list-post-written {
      span {
        font-weight: bold

      }
    }

    small {
      font-size: 12px;
      opacity: .7
    }
}

.list-item-header  {
  @extend .list-item;
  //background-color: red;
  border-radius: 10px;
  border: 3px solid #eadb06;
  cursor: pointer;
    &:hover {
      border-color: red;
    }
}

.list-item-2 {
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.03);
  border: 1px solid #C1C1C1;
  padding: 40px;
  box-sizing: border-box;
  transition: .3s;
  margin-bottom: 10px;
  overflow: hidden;
  &:hover {
    border-color: $colortheme;
    .list-post-title {
      color: $colortheme;
    }
  }

  .list-item-2-img {
    margin-right: 20px;
      @media screen and (max-width: 839px) {
        margin-right: 0;
        margin-top: 20px;
      }
   }
}
